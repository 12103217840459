<template>
    <div class="card box widget-social pt-2 pb-2" :class="componentStyle">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="energy" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <OverlayPanel class="param-overlay" ref="shading" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagShading"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Behälter Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('shading')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Allgemein">
                        <Panel header="Füllung Hauptschalter" class="mt-0">
                            <template #icons>
                                <i class="fi fi-rr-switch bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
							<div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataShading('tankFillEnable').value" /><span class="ml-2">{{ this.getDiagDataShading('tankFillEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
						</Panel>
                        <Panel header="Einstellungen Füllung" class="mt-0">
                            <template #header>
                                <p>Füllfreigabe</p>
                                <p class="fg-yellow">{{ this.getModuleValue('OUT_FillLevel').value + this.getModuleValue('OUT_FillLevel').unit }}</p>
                            </template>
                            <template #icons>
                                <i class="fi fi-rr-arrows-h-copy bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="tankFillStartLevel" ref="tankFillStartLevel" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('tankFillStartLevel').value"
                                            :suffix="' ' + this.getDiagDataShading('tankFillStartLevel').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="tankFillStartLevel" class="mr-2">{{ this.getDiagDataShading('tankFillStartLevel').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="tankFillEndLevel" ref="tankFillEndLevel" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('tankFillEndLevel').value"
                                            :suffix="' ' + this.getDiagDataShading('tankFillEndLevel').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="tankFillEndLevel" class="mr-2">{{ this.getDiagDataShading('tankFillEndLevel').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="tankFillDelay" ref="tankFillDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataShading('tankFillDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="tankFillDelay" class="mr-2">{{ this.getDiagDataShading('tankFillDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarmeinstellungen">
                        <Panel header="Alarmeinstellungen Minimum-Niveau">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="tankAlarmMin" ref="tankAlarmMin" :min="0" :max="100"
                                            v-model="getDiagDataShading('tankAlarmMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataShading('tankAlarmMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="tankAlarmMin" class="mr-2">{{ getDiagDataShading('tankAlarmMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataShading('tankAlarmMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Maximum-Niveau" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="tankAlarmMax" ref="tankAlarmMax" :min="0" :max="100"
                                            v-model="getDiagDataShading('tankAlarmMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataShading('tankAlarmMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="tankAlarmMax" class="mr-2">{{ getDiagDataShading('tankAlarmMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataShading('tankAlarmMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Nachfülldauer" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="tankAlarmRefillTime" ref="tankAlarmRefillTime" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataShading('tankAlarmRefillTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="tankAlarmRefillTime" class="mr-2">{{ this.getDiagDataShading('tankAlarmRefillTime').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataShading('tankAlarmRefillTimePrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Sensoreinstellungen">
                        <Panel header="Sensorskalierung" class="mt">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tankSensInMin" ref="tankSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataShading('tankSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tankSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tankSensOutMin" ref="tankSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataShading('tankSensOutMin').value"
                                                :suffix="` ${this.getDiagDataShading('tankSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tankSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tankSensInMax" ref="tankSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataShading('tankSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tankSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tankSensOutMax" ref="tankSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataShading('tankSensOutMax').value"
                                                :suffix="` ${this.getDiagDataShading('tankSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tankSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Filterkonstante" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tankSensFilter" ref="tankSensFilter" :min="0" :max="100"
                                                v-model="this.getDiagDataShading('tankSensFilter').value" mode="decimal"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tankSensFilter" class="mr-2">Filterkonstante</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleShading" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('shading')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel class="param-overlay" ref="minmax" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagMinMax"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '900px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Bewässerungs-Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('minmax')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Betriebseinstellungen">
                        <Panel header="Zentraler Halt">
                            <template #icons>
                                <i class="fi fi-rr-pause bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                        Der <b>Zentrale Halt</b> stoppt, wenn aktiv,  alle Bewässerungsvorgänge dieser Unit. Er muss wieder <b>manuell</b> deaktiviert werden. Danach werden alle Vorgänge fortgesetzt.
                                    </InlineMessage>
                                <div class="field col">
                                    <div class="mb-3">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataMinMax('setHold').value" /><span class="ml-2">{{ this.getDiagDataMinMax('setHold').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Reset (Zurücksetzen)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-redo-alt bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                    Der <b>Zentrale Reset</b> setzt alle Ventile und Gruppen auf 0 zurück. Er wird nach 5 Sekunden wieder <b>automatisch</b> deaktiviert. Danach beginnen alle Zähler bei 0 an zu zählen.
                                </InlineMessage>
                                <div class="field col">
                                    <div class="mb-3">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataMinMax('setReset').value" /><span class="ml-2">{{ this.getDiagDataMinMax('setReset').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Durchfluss">
                        <Panel header="Sensor verwenden" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-salt-pepper bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('flowSensEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('flowSensEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Einstellungen Kapazitäten" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-angle-double-small-right bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="maxFlowrate" ref="maxFlowrate" :min="0" :max="5000"
                                            v-model="getDiagDataMinMax('maxFlowrate').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('maxFlowrate').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="maxFlowrate" class="mr-2">{{ getDiagDataMinMax('maxFlowrate').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="maxCapacity" ref="maxCapacity" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('maxCapacity').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('maxCapacity').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="maxCapacity" class="mr-2">{{ getDiagDataMinMax('maxCapacity').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen" class="mt-3" v-if="this.getDiagDataMinMax('flowSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmFlowDiffMinus" ref="alarmFlowDiffMinus" :min="-100" :max="100"
                                            v-model="getDiagDataMinMax('alarmFlowDiffMinus').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmFlowDiffMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmFlowDiffMinus" class="mr-2">{{ getDiagDataMinMax('alarmFlowDiffMinus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmFlowDiffMinusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmFlowDiffPlus" ref="alarmFlowDiffPlus" :min="-100" :max="100"
                                            v-model="getDiagDataMinMax('alarmFlowDiffPlus').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmFlowDiffPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmFlowDiffPlus" class="mr-2">{{ getDiagDataMinMax('alarmFlowDiffPlus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmFlowDiffPlusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmFlowLeak" ref="alarmFlowLeak" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('alarmFlowLeak').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmFlowLeak').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmFlowLeak" class="mr-2">{{ getDiagDataMinMax('alarmFlowLeak').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmFlowLeakPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmverzögerungen" class="mt-3" v-if="isMaster && this.getDiagDataMinMax('flowSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-half-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="alarmFlowDiffDelay" ref="alarmFlowDiffDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('alarmFlowDiffDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="alarmFlowDiffDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmFlowDiffDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="alarmFlowLeakDelay" ref="alarmFlowLeakDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('alarmFlowLeakDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="alarmFlowLeakDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmFlowLeakDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung" class="mt-3" v-if="isMaster && this.getDiagDataMinMax('flowSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-unlock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="flowSensInMin" ref="flowSensInMin" :min="0" :max="32767"
                                                v-model="getDiagDataMinMax('flowSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="flowSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="flowSensOutMin" ref="flowSensOutMin" :min="-1000" :max="1000"
                                                v-model="getDiagDataMinMax('flowSensOutMin').value"
                                                :suffix="` ${this.getDiagDataMinMax('flowSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="flowSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="flowSensInMax" ref="flowSensInMax" :min="0" :max="32767"
                                                v-model="getDiagDataMinMax('flowSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="flowSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="flowSensOutMax" ref="flowSensOutMax" :min="-100000" :max="100000"
                                                v-model="getDiagDataMinMax('flowSensOutMax').value"
                                                :suffix="` ${this.getDiagDataMinMax('flowSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="flowSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Mischbecken">
                        <Panel header="Auffüll- und Absenkzeiten">
                            <template #icons>
                                <i class="fi fi-rr-time-half-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="mixTankFillTime" ref="mixTankFillTime" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('mixTankFillTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="mixTankFillTime" class="mr-2">{{ this.getDiagDataMinMax('mixTankFillTime').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="mixTankFlushTime" ref="mixTankFlushTime" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('mixTankFlushTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="mixTankFlushTime" class="mr-2">{{ this.getDiagDataMinMax('mixTankFlushTime').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensor verwenden" v-if="isMaster" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-salt-pepper bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('mixSensEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('mixSensEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Mischbehälter - Digital Eingang" class="mt-3" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <InputMask id="alarmMixtankDelay" ref="alarmMixtankDelay" mask="99:99:99" placeholder=""
                                                slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('alarmMixtankDelay').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="alarmMixtankDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmMixtankDelay').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Mischbehälter - Sensor Alarmgrenzen" class="mt-3" v-if="this.getDiagDataMinMax('mixSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmMixtankMin" ref="alarmMixtankMin" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('alarmMixtankMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmMixtankMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmMixtankMin" class="mr-2">{{ getDiagDataMinMax('alarmMixtankMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmMixtankMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmMixtankMax" ref="alarmMixtankMax" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('alarmMixtankMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmMixtankMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmMixtankMax" class="mr-2">{{ getDiagDataMinMax('alarmMixtankMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmMixtankMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung" class="mt-3" v-if="isMaster && this.getDiagDataMinMax('mixSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-unlock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="mixSensInMin" ref="mixSensInMin" :min="0" :max="32767"
                                                v-model="getDiagDataMinMax('mixSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="mixSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="mixSensOutMin" ref="mixSensOutMin" :min="-1000" :max="1000"
                                                v-model="getDiagDataMinMax('mixSensOutMin').value"
                                                :suffix="` ${this.getDiagDataMinMax('mixSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="mixSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="mixSensInMax" ref="mixSensInMax" :min="0" :max="32767"
                                                v-model="getDiagDataMinMax('mixSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="mixSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="mixSensOutMax" ref="mixSensOutMax" :min="-100000" :max="100000"
                                                v-model="getDiagDataMinMax('mixSensOutMax').value"
                                                :suffix="` ${this.getDiagDataMinMax('mixSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="mixSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Anlagendruck">
                        <Panel header="Sensor verwenden" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-salt-pepper bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('pressureSensEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('pressureSensEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen" class="mt-3" v-if="this.getDiagDataMinMax('pressureSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmPressureMin" ref="alarmPressureMin" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('alarmPressureMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmPressureMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureMin" class="mr-2">{{ getDiagDataMinMax('alarmPressureMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmPressureMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmPressureMax" ref="alarmPressureMax" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('alarmPressureMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmPressureMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureMax" class="mr-2">{{ getDiagDataMinMax('alarmPressureMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmPressureMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmverzögerungen" class="mt-3" v-if="isMaster && this.getDiagDataMinMax('pressureSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-half-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="alarmPressureDelay" ref="alarmPressureDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('alarmPressureDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="alarmPressureDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmPressureDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung" class="mt-3" v-if="isMaster && this.getDiagDataMinMax('pressureSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-unlock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensInMin" ref="pressureSensInMin" :min="0" :max="32767"
                                                v-model="getDiagDataMinMax('pressureSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensOutMin" ref="pressureSensOutMin" :min="-1000" :max="1000"
                                                v-model="getDiagDataMinMax('pressureSensOutMin').value"
                                                :suffix="` ${this.getDiagDataMinMax('pressureSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensInMax" ref="pressureSensInMax" :min="0" :max="32767"
                                                v-model="getDiagDataMinMax('pressureSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensOutMax" ref="pressureSensOutMax" :min="-100000" :max="100000"
                                                v-model="getDiagDataMinMax('pressureSensOutMax').value"
                                                :suffix="` ${this.getDiagDataMinMax('pressureSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Verzögerungen" v-if="isMaster">
                        <Panel header="Pumpenverzögerungen">
                            <template #icons>
                                <i class="fi fi-rr-time-half-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="fillPumpDelayStart" ref="fillPumpDelayStart" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('fillPumpDelayStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="fillPumpDelayStart" class="mr-2">{{ this.getDiagDataMinMax('fillPumpDelayStart').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="fillPumpDelayEnd" ref="fillPumpDelayEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('fillPumpDelayEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="fillPumpDelayEnd" class="mr-2">{{ this.getDiagDataMinMax('fillPumpDelayEnd').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="sysPumpDelayStart" ref="sysPumpDelayStart" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('sysPumpDelayStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="sysPumpDelayStart" class="mr-2">{{ this.getDiagDataMinMax('sysPumpDelayStart').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="sysPumpDelayEnd" ref="sysPumpDelayEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('sysPumpDelayEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="sysPumpDelayEnd" class="mr-2">{{ this.getDiagDataMinMax('sysPumpDelayEnd').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Systempumpe">
                        <Panel header="Systempumpe 2">
                            <template #icons>
                                <i class="fi fi-rr-salt-pepper bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('sysPump2Enable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('sysPump2Enable').label }}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Pumpensequenzierung" class="mt-3" v-if="this.getDiagDataMinMax('sysPump2Enable').value">
                            <template #icons>
                                <i class="fi fi-rr-signal-alt-2 bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="sysPump1Start" ref="sysPump1Start" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('sysPump1Start').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('sysPump1Start').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="sysPump1Start" class="mr-2">{{ getDiagDataMinMax('sysPump1Start').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="sysPump2Start" ref="sysPump2Start" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('sysPump2Start').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('sysPump2Start').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="sysPump2Start" class="mr-2">{{ getDiagDataMinMax('sysPump2Start').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="sysPumpsStart" ref="sysPumpsStart" :min="0" :max="100"
                                            v-model="getDiagDataMinMax('sysPumpsStart').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('sysPumpsStart').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="sysPumpsStart" class="mr-2">{{ getDiagDataMinMax('sysPumpsStart').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Serviceparameter" v-if="isMaster">
                        <Panel header="Zentraler Halt">
                            <template #icons>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <InputMask id="alarmHoldDelay" ref="alarmHoldDelay" mask="99:99:99" placeholder=""
                                                slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('alarmHoldDelay').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="alarmHoldDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmHoldDelay').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmHoldPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Externer Unit Alarm" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-bell-ring bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <InputMask id="alarmExtAlarmDelay" ref="alarmExtAlarmDelay" mask="99:99:99" placeholder=""
                                                slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('alarmExtAlarmDelay').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="alarmExtAlarmDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmExtAlarmDelay').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmExtAlarmPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarmausgang" v-if="isMaster">
                        <widgetAlarmOutput :value="this.getDiagDataMinMax('alarmAssignedOutput')" @setOutput="($value) => {this.getDiagDataMinMax('alarmAssignedOutput').value = $value}"></widgetAlarmOutput>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_misc`" :department="moduleDepartment.key" :exchange="true" format="json"
                    @diagAbort="diagToggleMinMax"
                    @diagSave="saveDiag('minmax')"
                    @import="($value) => {this.nodeDataMinMax = $value}"
                    :exportObject="this.nodeDataMinMax"
                ></widgetDialogFooter>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '900px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Durchfluss und Druck Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Allgemein">
                        <Panel header="Schirmdämpfung (Lichtdämpfung)">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="screenLightFactor" ref="screenLightFactor" :min="0" :max="100"
                                            v-model="getDiagDataCfg('screenLightFactor').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('screenLightFactor').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="screenLightFactor" class="mr-2">{{ getDiagDataCfg('screenLightFactor').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarme">
                        <Panel header="Alarmeinstellungen Anlagenstörung (Digital-IN)" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataCfg('externalAlarmLogic').value" /><span class="ml-2">{{ this.getDiagDataCfg('externalAlarmLogic').label }}</span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('externalAlarmPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMisc" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('misc')" />
                    </div>
                </div>
            </template>
        </Dialog>

        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="flex mt-1 ml-3">
                        <widget-mini-icon label="HALT" :value="this.getModuleValue('OUT_Hold')" icon="fi-rr-shield-exclamation" colorActive="fg-red"></widget-mini-icon>
                        <widget-mini-icon label="ALARM" :value="this.getModuleValue('OUT_Alarm')" icon="fi-rr-bell-ring" colorActive="fg-red"></widget-mini-icon>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="runningState" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :title="'Zentraler Halt/Störung'" :value1="this.getModuleValue('CFG.setHold')" :value2="this.getModuleValue('OUT_Alarm')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Füllpumpe/Düngerezept'" :value1="this.getModuleValue('OUT_FillPump')" :value2="this.getModuleValue('OUT_FertiPresetStr')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Systempumpe 1/2'" :value1="this.getModuleValue('OUT_SysPump')" :value2="this.getModuleValue('OUT_SysPump2')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Durchfluss/Kapazität aktuell'" :value1="this.getModuleValue('OUT_CurrentFlow')" :value2="this.getModuleValue('OUT_CurrentFlowPercent')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Kapazität berechnet'" :value1="this.getModuleValue('OUT_CurrentCapacityCalc')" :value2="this.getModuleValue('OUT_CurrentCapacity')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Anlagendruck/Mischbecken'" :value1="this.getModuleValue('OUT_CurrentPressure')" :value2="this.getModuleValue('OUT_MixtankLevel')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Laufzeit'" :value1="this.getModuleValue('OUT_CurrentRuntime')" :value2="this.getModuleValue('OUT_CurrentResttime')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'letzter Start/Ende'" :value1="this.getModuleValue('OUT_LastStartDt')" :value2="this.getModuleValue('OUT_LastEndDt')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Einstellungen" subtitle="Allgemeine Einstellungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="cobalt"
                                @click="toggleOP($event, 'minmax')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="aktive Gruppe" :value="grpListActive" :moduleData="moduleData" icon="fi fi-rr-pulse" />
                        </div>
                    </div>
                    <div class="hidden md:flex justify-content-between flex-wrap mt-2">
                        <div>
                            <span class="block mb-2">Gruppenwarteliste</span>
                            <VirtualScroller :items="grpListQueueData" :itemSize="40" class="mt-2 text-sm bg-darkGrayBlue border-1 surface-border border-round" style="width: 140px; height: 180px">
                                <template v-slot:item="{ item, options }">
                                    <div :class="['flex align-items-center p-2', { 'surface-hover': options.odd }]" style="height: 2rem">{{ item }}</div>
                                </template>
                            </VirtualScroller>
                        </div>
                        <div>
                            <span class="block mb-2">Ventile aktiv</span>
                            <VirtualScroller :items="valveListActiveData" :itemSize="40" class="mt-2 text-sm bg-cobalt border-1 surface-border border-round" style="width: 140px; height: 180px">
                                <template v-slot:item="{ item, options }">
                                    <div :class="['flex align-items-center p-2', { 'surface-hover': options.odd }]" style="height: 2rem">{{ item }}</div>
                                </template>
                            </VirtualScroller>
                        </div>
                        <div>
                            <span class="block mb-2">Ventilwarteliste</span>
                            <VirtualScroller :items="valveListQueueData" :itemSize="40" class="mt-2 text-sm bg-grayBlue border-1 surface-border border-round" style="width: 140px; height: 180px">
                                <template v-slot:item="{ item, options }">
                                    <div :class="['flex align-items-center p-2', { 'surface-hover': options.odd }]" style="height: 2rem">{{ item }}</div>
                                </template>
                            </VirtualScroller>
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_Running').icon" :node="getModuleValue('OUT_Running')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniIcon from '@/components/fragments/widgetMiniIcon.vue';
import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetIrrigationController',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniIcon,
        widgetAlarmOutput,
        widgetDialogFooter,
        moduleIconEnat,
        widgetTitleBar,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                if (this.alarmStatus > 0) background = 'alarm';
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        alarmStatus1: function () {
            // if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
            //     let iCol;
            //     const index = findIndexByDisplayName(this.nodeEntry.children, 'AlarmStatus');
            //     if (this.nodeEntry.children[index].value.length > 0) {
            //         iCol = ' alarm';
            //     } else {
            //         iCol = '';
            //     }
            //     return iCol;
            // } else {
            //     return 'mif-blocked';
            // }
            return '';
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriodeHzg.PeriodIndex').value;
        },
        runningState: function () {
            if (this.getModuleValue('OUT_Running').value === true) return '🟢Ein';
            else return '🔴Aus';
        },
        grpListActive: function () {
            if (this.getModuleValue('OUT_GrpListActive.periodIndex').value > -1) {
                return this.getModuleValue('OUT_GrpListActive.periodName').value;
            } else {
                return "keine Gruppe aktiv";
            }
        },
        grpListQueue: function () {
            const size = this.getModuleValue('OUT_GrpListQueue').size;
            if (size > 0) {
                let list = "<ul>";
                for (let i=0; i<size; i++) {
                    const index = this.getModuleValue(`OUT_GrpListQueue[${i}].periodIndex`).value;
                    const grp = this.getModuleValue(`OUT_GrpListQueue[${i}].periodName`).value;
                    if (grp !== null && index >= 0) {
                        list+=`<li>${grp}</li>`;
                    }
                }
                list+="</ul>";
                return list;
            } else {
                return 'keine Gruppe eingereiht';
            }
        },
        grpListQueueData: function () {
            let arr = [];
            const size = this.getModuleValue('OUT_GrpListQueue').size;
            if (size > 0) {
                for (let i=1; i<size; i++) {
                    const index = this.getModuleValue(`OUT_GrpListQueue[${i}].periodIndex`).value;
                    const grp = this.getModuleValue(`OUT_GrpListQueue[${i}].periodName`).value;
                    if (grp !== null && index >= 0) {
                        arr.push(grp);
                    }
                }
            }
            return arr;
        },
        valveListQueue: function () {
            const size = this.getModuleValue('OUT_ValveListQueue').size;
            if (size > 0) {
                let list = "<ul>";
                for (let i=0; i<size; i++) {
                    const grp = this.getModuleValue(`OUT_ValveListQueue[${i}].valveName`).value;
                    const index = this.getModuleValue(`OUT_ValveListQueue[${i}].valveIndex`).value;
                    if (grp !== null && index >= 0) {
                        list+=`<li>${grp}</li>`;
                    }
                }
                list+="</ul>";
                return list;
            } else {
                return 'kein Ventil eingereiht';
            }
        },
        valveListQueueData: function () {
            let arr = [];
            const size = this.getModuleValue('OUT_ValveListQueue').size;
            if (size > 0) {
                for (let i=1; i<size; i++) {
                    const index = this.getModuleValue(`OUT_ValveListQueue[${i}].periodIndex`).value;
                    const grp = this.getModuleValue(`OUT_ValveListQueue[${i}].periodName`).value;
                    if (grp !== null && index >= 0) {
                        arr.push(grp);
                    }
                }
            }
            return arr;
        },
        valveListActive: function () {
            const size = this.getModuleValue('OUT_ValveListActive').size;
            if (size > 0) {
                let list = "<ul>";
                for (let i=0; i<size; i++) {
                    const grp = this.getModuleValue(`OUT_ValveListActive[${i}].valveName`).value;
                    const index = this.getModuleValue(`OUT_ValveListActive[${i}].valveIndex`).value;
                    if (grp !== null && index >= 0 && typeof grp === 'string') {
                        list+=`<li>${grp}</li>`;
                    }
                }
                list+="</ul>";
                return list;
            } else {
                return 'kein Ventil eingereiht';
            }
        },
        valveListActiveData: function () {
            let arr = [];
            const size = this.getModuleValue('OUT_ValveListActive').size;
            if (size > 0) {
                for (let i=0; i<size; i++) {
                    const index = this.getModuleValue(`OUT_ValveListActive[${i}].valveIndex`).value;
                    const grp = this.getModuleValue(`OUT_ValveListActive[${i}].valveName`).value;
                    const resttime = this.getModuleValue(`OUT_ValveListActive[${i}].valveResttime`).value;
                    const valve = `${grp} (${resttime})`;
                    if (grp !== null && index >= 0 && typeof grp === 'string') {
                        arr.push(valve);
                    }
                }
            }
            return arr;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataMinMax: [],
            nodeDataMinMaxOld: [],
            nodeDataEnergy: [],
            nodeDataEnergyOld: [],
            nodeDataShading: [],
            nodeDataShadingOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            showDiagName: false,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagEnergy: false,
            showDiagShading: false,
            showDiagMisc: false,
            showDiagMinMax: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'minmax');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataEnergy(displayName) {
            if (this.nodeDataEnergy.length > 0 && displayName) {
                const result = this.nodeDataEnergy.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataShading(displayName) {
            if (this.nodeDataShading.length > 0 && displayName) {
                const result = this.nodeDataShading.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataMinMax(displayName) {
            if (this.nodeDataMinMax.length > 0 && displayName) {
                const result = this.nodeDataMinMax.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'energy') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataEnergy = JSON.parse(JSON.stringify(response));
                            this.nodeDataEnergyOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataEnergy);
                            console.log(this.nodeDataEnergyOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagEnergy = true;
                }); 
            } else if (grp === 'shading') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataShading = JSON.parse(JSON.stringify(response));
                            this.nodeDataShadingOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataShading);
                            console.log(this.nodeDataShadingOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagShading = true;
                }); 
            } else if (grp === 'minmax') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataMinMax = JSON.parse(JSON.stringify(response));
                            this.nodeDataMinMaxOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataMinMax);
                            console.log(this.nodeDataMinMaxOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMinMax = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagToggleEnergy() {
            if (this.showDiagEnergy) {
                this.showDiagEnergy = false;
            } else {
                this.showDiagEnergy = true;
            }
            this.hideOP();
        },
        diagToggleShading() {
            if (this.showDiagShading) {
                this.showDiagShading = false;
            } else {
                this.showDiagShading = true;
            }
            this.hideOP();
        },
        diagToggleMinMax() {
            if (this.showDiagMinMax) {
                this.showDiagMinMax = false;
            } else {
                this.showDiagMinMax = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'energy') {
                    console.log(this.nodeDataEnergy);
                    console.log(this.nodeDataEnergyOld);
                    const flatHzg = this.nodeDataEnergy.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataEnergyOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'shading') {
                    console.log(this.nodeDataShading);
                    console.log(this.nodeDataShadingOld);
                    const flatHzg = this.nodeDataShading.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataShadingOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'minmax') {
                    console.log(this.nodeDataMinMax);
                    console.log(this.nodeDataMinMaxOld);
                    const flatHzg = this.nodeDataMinMax.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataMinMaxOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'energy') this.showDiagEnergy = false;
                            if (diag === 'shading') this.showDiagShading = false;
                            if (diag === 'minmax') this.showDiagMinMax = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    this.dialogLoading = false;
                    if (diag === 'energy') this.showDiagEnergy = false;
                    if (diag === 'shading') this.showDiagShading = false;
                    if (diag === 'minmax') this.showDiagMinMax = false;
                    if (diag === 'misc') this.showDiagMisc = false;
                    this.hideOP();
                }
            }
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>